.container {
  display: flex;
  align-items: center;
  padding-left: 6px;
  background: #f2f4f7;
  height: 61px;
  box-shadow: 0 1px 0 #d3d5d9, 0 2px 0 #ffffff;
  margin-bottom: 42px;
}

.textWrapper {
  margin-left: 17px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  letter-spacing: 0.03em;

  .color {
    color: #00a2ff;
  }
}