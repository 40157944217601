@use "./colors" as colors;

html, body {
  font-family: 'Source Sans Pro', 'Helvetica', 'Arial', sans-serif;
  position: relative;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: colors.$bodyBackgroundColor;
;
}
