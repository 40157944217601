@mixin arrow($value, $position: left) {
  content: " ";
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  #{$position}: $value;
  top: -5px;
  background: #ffffff;
  border-radius: 2px;
  transform: rotate(-135deg);
  filter: drop-shadow(1px 1px 0 #d3d5d9);
}

@mixin popperLink() {
  a {
    font-size: 16px;
    letter-spacing: 0.02em;
    color: #202730;
    text-decoration: none;
    display: block;
    padding: 10px 0;

    &:hover {
      color: #00A2FF;
    }
  }
}

.container {
  display: flex;
  align-items: center;
  padding-left: 10px;
  background: #f2f4f7;
  height: 61px;
  box-shadow: 0 1px 0 #d3d5d9, 0 2px 0 #ffffff;
  overflow: hidden;
}

.navigation {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;

  &Links {
    display: flex;
  }

  &Link {
    color: #4D5C70;
    font-weight: 600;
    text-decoration: none;
    font-size: 16px;
    letter-spacing: 0.02em;
    margin-left: 34px;
    display: block;
    padding: 10px 0;

    &:hover {
      color: #00A2FF;
    }

    &:first-child {
      margin-left: 52px;
    }
  }

  &Controls {
    display: flex;
    align-items: center;
    margin-right: 20px;

    &Helper {
      margin-right: 8px;
      cursor: pointer;

      &:hover {
        svg {
          path {
            stroke: #00A2FF;
          }
        }
      }
    }

    &Account {
      cursor: pointer;

      &:hover {
        svg {
          path {
            stroke: #00A2FF;
          }
        }
      }
    }

    &Hamburger {
      cursor: pointer;
      height: 24px;

      &:hover {
        svg {
          rect {
            fill: #00A2FF;
          }
        }
      }
    }

    &Popper {
      border: 1px solid #D3D5D9 !important;
      box-sizing: border-box !important;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
      border-radius: 8px !important;
      min-width: 113px !important;
      top: 71px !important;
      right: 15px !important;
      padding: 10px 13px !important;
      overflow-y: visible !important;
      max-width: 800px !important;

      &HelperLink {
        @include popperLink;
      }

      &AccountLink {
        @include popperLink;
      }

      &SignOutLink {
        display: flex;
        align-items: flex-end;
        cursor: pointer;
        padding: 8px 0;

        button {
          cursor: pointer;
          font-size: 16px;
          letter-spacing: 0.02em;
          color: #7990A1;
          margin-right: 4px;
        }

        &:hover {
          button {
            color: #00A2FF;
          }

          svg {
            path {
              stroke: #00A2FF;
            }
          }
        }
      }

      &Divider {
        border-top: 1px solid #D3D5D9;
        min-width: 82px;
        height: 0;
      }

      &ArrowHelper {
        &:after {
          @include arrow(50px);
        }
      }

      &ArrowAccount {
        &:after {
          @include arrow(15px, right);
        }
      }

      &ArrowHamburger {
        &:after {
          @include arrow(12px, right);
        }
      }
    }

    &Link {
      border-radius: 12px !important;
      background: #F5F7FA !important;
      width: 203px !important;
      height: 38px !important;
      font-weight: 700 !important;
      font-size: 14px !important;
      letter-spacing: 0.03em !important;
      padding: 0 22px !important;
      color: #00A2FF !important;
      border-color: #00A2FF !important;
      margin-right: 32px !important;
      box-shadow: 4px 4px 9.4px 0.6px rgb(32 39 48 / 10%), -4px -4px 9.4px 0.6px rgb(255 255 255) !important;

      &:hover,
      &:active {
        color: #FFFFFF !important;
        background: #00AEFF !important;
        border: 1px solid #00AEFF !important;
      }

      div {
        margin-top: 2px;
      }
    }

    .open {
      pointer-events: none;
    }
  }

  .active {
    color: #202730;
    pointer-events: none;
    position: relative;

    &:after {
      content: ' ';
      height: 5px;
      width: calc(100% + 10px);
      display: block;
      position: absolute;
      background: linear-gradient(180deg, #33CCFD 0%, #00A2FF 100%);
      box-shadow: -3px -3px 4px 2px #FFFFFF, 3px 3px 4px 1px rgba(32, 39, 48, 0.25);
      border-radius: 6px;
      bottom: -12px;
      left: -5px;
    }
  }

  .mobileActive {
    a {
      color: #00A2FF;
      pointer-events: none;
    }
  }
}


@media (max-width: 962px) {
  .navigation {
    justify-content: flex-end;
  }
}
