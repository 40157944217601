.container {
  display: flex;
  align-items: center;
  min-height: 61px;
  background: #f2f4f7;
  box-shadow: 0px -1px 0px #d3d5d9, 0px -2px 0px #ffffff;
  padding: 0 32px;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #4d5c70;
  justify-content: space-between;
  margin-top: 60px;
}

.actions {
  display: flex;
  gap: 10px;

  .contrast {
    display: flex;
  }

  .contrast {
    cursor: pointer;

    &:hover {
      color: #00a2ff;
    }

    .text {
      margin-left: 10px;
      margin-right: 20px;
      text-decoration-line: underline;
    }
  }
}

.links {
  .link {
    &:active,
    &:link,
    &:visited,
    &:focus {
      color: #4d5c70;
    }

    text-decoration-line: underline;
    margin-left: 24px;

    &:hover {
      color: #00a2ff;
    }
  }
}

.languagePopper {
  bottom: 30px !important;
  right: 6px !important;
  min-width: 110px !important;
  border: 1px solid #D3D5D9 !important;
  padding: 6px 13px !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 8px rgb(0 0 0 / 20%) !important;
  max-width: none !important;

  &Text {
    cursor: pointer;
    padding: 4px;

    &:hover {
      color: #00A2FF;
    }
  }
}

.languageButton {
  height: 24px !important;
  min-width: 40px !important;
  margin-left: 0 !important;

  & > span {
    display: flex;
    align-items: center;
  }



  &Text {
    font-weight: 400;
  }

  &:hover {
    .languageButtonText {
      color: #00A2FF !important;
    }

    & svg path {
      fill: #00A2FF;
    }
  }
}

@media (max-width: 820px) {
  .container {
    min-height: 136px;

    .links,
    .actions {
      display: flex;
      flex-direction: column;
    }

    .links {
      .link {
        margin-left: 0;
        margin-top: 10px;
      }
    }

    .actions {
      .language {
        margin-top: 10px;
      }

      .contrast {
        margin-top: 10px;
      }
    }
  }
}
